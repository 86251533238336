const APP_NAME = "CHM"
import EnvConstants from '../constants';
class LogTool {
  static log(sender, msg) {
    if(EnvConstants.isProduction===false){
      if(typeof(msg)==='string') {
        console.log(`${APP_NAME} - [${sender}] : ${msg}`)
      } else if (msg instanceof Map) {
        console.log(`${APP_NAME} - [${sender}] : ${JSON.stringify(Array.from(msg))}`)
      } else {
        console.log(`${APP_NAME} - [${sender}] : ${JSON.stringify(msg)}`)
      }
    }
  }
  static info(sender, msg) {
    if(EnvConstants.isProduction===false){
      if(typeof(msg)==='string') {
        console.info(`${APP_NAME} - [${sender}] : ${msg}`)
      } else if (msg instanceof Map) {
        console.info(`${APP_NAME} - [${sender}] : ${JSON.stringify(Array.from(msg))}`)
      } else {
        console.info(`${APP_NAME} - [${sender}] : ${JSON.stringify(msg)}`)
      }
    }
  }
  static error(sender, msg) {
    if(EnvConstants.isProduction===false){
      if(typeof(msg)==='string') {
        console.error(`${APP_NAME} - [${sender}] : ${msg}`)
      } else if (msg instanceof Map) {
        console.error(`${APP_NAME} - [${sender}] : ${JSON.stringify(Array.from(msg))}`)
      } else {
        console.error(`${APP_NAME} - [${sender}] : ${JSON.stringify(msg)}`)
      }
    }
  }
}

export default LogTool;
