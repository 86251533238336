// assets/js/lib.js
import LogTool from './tools/log-tool';

class Lib {
	constructor() { }

	static htmlEntities(str) {
		return String(str)
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;');
	}
	/**
	 * var number = getUrlVars()["x"];
	 * var mytext = getUrlVars()["text"]
	 */
	static getUrlVars() {
		const ref = this;
		let vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
			vars[key] = ref.htmlEntities(value);
		});
		return vars;
	}
	static urlencode(str) {
		str = (str + '')
		return encodeURIComponent(str)
			.replace(/!/g, '%21')
			.replace(/'/g, '%27')
			.replace(/\(/g, '%28')
			.replace(/\)/g, '%29')
			.replace(/\*/g, '%2A')
			.replace(/~/g, '%7E')
			.replace(/%20/g, '+')
	}
	/**
	 * var mytext = getUrlParam('text','Empty');
	 */
	static getUrlParam(parameter, defaultvalue){
		let paramValue = defaultvalue;
		if ( window.location.href.indexOf(parameter) > -1 ) {
			paramValue = this.getUrlVars()[parameter];
		}
		//return decodeURI(paramValue);
		return decodeURIComponent((paramValue + '').replace(/\+/g, '%20'));
	}
	static scrollToTop(offsetTop) {
		if ( typeof offsetTop === 'object' ) {
			offsetTop = 0;
		}
		//let duration = offsetTop * 0.8;
		//if (offsetTop > 1000) duration = offsetTop * 0.6;
		//if (duration < 300) duration = 300;
		let duration = 400;
		$("html, body").animate({
				scrollTop: offsetTop
			}, duration, "swing" //easeOutCubic ; easeOuQuad
		);
	}
	static slugify(str) {
		str = str.replace(/^\s+|\s+$/g, '')
			.toLowerCase()  // Make the string lowercase
			.trim();        // Remove whitespace from both sides of a string
	
		// Remove accents, swap ñ for n, etc
		const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'";
		const to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa-------";
		for (let i=0, l=from.length ; i<l ; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}
		
		return str
			.replace(/[^a-z0-9 -]/g, '') // Remove invalid chars
			.replace(/\s+/g, '-')  // Collapse whitespace and replace by -  
			.replace(/-+/g, '-')  // Collapse dashes
			.replace(/-$/, '');  // remove '-' as last character
	}
	static shorten(text, max) {
		return text && text.length > max
			? text
				.slice(0, max)
				.split(" ")
				.slice(0, -1)
				.join(" ") + '...'
			: text;
	}
	static strip_tags(string) {
		return string.replace(/(<([^>]+)>)/gi, "");
	}
	static capitalizeWords(string) {
		return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
	}
	static scrollToInvalid(form) {
		// console.log(form);
		// form = $(form); // Convert to JQuery element;
		let firstInputError = form.find('.form__group.has-error').first();//form.find(':input[aria-invalid="true"]').first();
		//var elementOffset = firstInputError.offset().top - 20;//firstInputError.parents('.form-group').offset().top - 20;
		let elementOffset = firstInputError.offset().top - 80;
		let pageOffset = window.pageYOffset;
		// don't scroll if the element is already in view
		if (elementOffset > pageOffset && elementOffset < pageOffset + window.innerHeight) {
			return true;
		}
		this.scrollToTop(elementOffset);
	}
	static reload_captcha(tagId) {
		if ( tagId !== undefined ) {
			let img = document.getElementById(tagId);
			img.src = '/api/captcha?n=' + (new Date()).getTime();
		}
	}
	static showLoader(txtLoader) {
		txtLoader = typeof txtLoader !== "undefined" ? txtLoader : 'Chargement de la page...';

		const divLoader = `
			<div id="loader">
				<div id="loader-container" class="container">
					<div id="loader-logo" class="loader">
						<div id="logo-red" class="red"></div>
						<div id="logo-blue" class="blue"><span>CNP</span></div>
						<div id="logo-green" class="green"><svg height="40"><path d="m 2 45 a 48 36 0 0 1 90 0" fill="none" stroke="#00b4aa" stroke-width="8"></path></svg></div>
					</div>
					<p class="loader--text">${txtLoader}</p>
				</div>
			</div>
		`;

		document.body.insertAdjacentHTML('beforeend', divLoader);
		return true;
	}
	
	static hideLoader() {
		let elt = document.getElementById('loader');
		if ( elt !== null ) {
			elt.remove();
			// console.log("removeLoader");
		}
	}
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment

  //static async doAjax(url, args = "") {
	//static doAjax = async(url, args = "") => {
	//static doAjax = async({ url = '', data = {} } = {}) => {
	static doAjax = async({ url = '', data = {}, type = 'POST' }) => {
		let result;
		LogTool.info(`${Lib.name}`,`${type} - ${url}`);
		try {
			result = await $.ajax({
				url: 	url,
				type: type,
				data: data
			});
			return result;
		}
		catch (error) {
			//LogTool.error(`${Lib.name}`,`${error}`);
			//LogTool.error(`${Lib.name}`, JSON.stringify(error));
			return error;
		}
	}
}

export default Lib
